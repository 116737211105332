<template>
  <page v-loading="loading">
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="分类名称" prop="clazzName">
          <el-input v-model="params.clazzName" />
        </el-form-item>
      </el-form>
    </template>

    <template slot="headerRight">
      <el-button
        v-auth="'goodsClazz:admin:batchImport'"
        type="primary"
        @click="visible = true"
        >导入</el-button
      ><el-button
        v-auth="'goodsClazz:admin:add'"
        type="primary"
        @click="addHandler"
        >添加</el-button
      >
    </template>

    <div class="table-wrap">
      <el-table :data="tableData" border height="100%" style="width: 100%">
        <el-table-column
          show-overflow-tooltip
          prop="id"
          label="分类ID"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="clazzName"
          label="分类名称"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'goodsClazz:admin:update'"
              @click="editHandler(row)"
              type="text"
              >编辑</el-button
            >
            <el-button
              v-auth="'goodsClazz:admin:delete'"
              @click="delHandler(row)"
              type="text"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :currentItem="currentItem"
    />

    <ImportModal
      v-if="visible"
      @getData="getData(true)"
      :visible.sync="visible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal'
import ImportModal from './components/ImportModal'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      currentItem: null,
      addVisible: false,
      visible: false,
      tableData: [],
      params: {
        clazzName: '',
        limit: 20,
        page: 1
      },
      totalCount: 0
    }
  },
  components: {
    AddModal,
    ImportModal
  },
  created() {
    this.getData()
  },
  methods: {
    addHandler() {
      this.currentItem = null
      this.addVisible = true
    },
    editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    },
    async delHandler(row) {
      try {
        await this.$confirm(
          `删除后，所有供应商该分类下的商品将自动归到未分类下，确定删除？`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.classification.clazzDelHandler({ id: row.id })
          this.$message.success('删除成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      this.loading = true

      const sendData = JSON.parse(JSON.stringify(this.params))

      if (query) {
        sendData.page = 1
        sendData.limit = 20
      }

      sendData.currentPage = sendData.page
      sendData.pageSize = sendData.limit

      delete sendData.limit
      delete sendData.page

      try {
        const res = await this.$api.classification.getClazzList(sendData)
        this.cartEntity = res.cartEntity || {}

        const tableData = res.clazzList.list.map(item => {
          return item
        })

        this.tableData = tableData
        this.totalCount = res.clazzList.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
