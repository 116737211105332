<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="isCreated ? '新增分类' : '编辑分类'"
    :visible.sync="show"
    width="550px"
  >
    <el-form
      size="small"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <el-form-item label="分类名称" prop="clazzName">
        <el-input
          v-model="form.clazzName"
          placeholder="请输入分类名称"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="formSubmit" :loading="loading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const rules = {
      name: [
        {
          required: true,
          message: '请输入分类名称'
        }
      ]
    }

    return {
      rules,
      loading: false,
      form: {
        clazzName: ''
      }
    }
  },
  async created() {
    if (!this.isCreated) {
      this.form.clazzName = this.currentItem.clazzName
    }
  },

  methods: {
    formSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const sendData = { ...this.form }

          if (!this.isCreated) {
            sendData.id = this.currentItem.id
          }

          try {
            this.isCreated
              ? await this.$api.classification.clazzSave(sendData)
              : await this.$api.classification.clazzUpdate(sendData)
            this.$message.success('操作成功')
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
