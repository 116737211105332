<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="导入"
    :visible.sync="show"
    width="400px"
  >
    <div class="content">
      <div class="tip">
        请上传分类批量导入文件<el-button
          type="text"
          @click="getDownloadTemplateMchUrl"
          >（下载模板）</el-button
        >
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="submit"
        >确定</el-button
      >
    </span>

    <el-upload
      drag
      ref="upload"
      :on-change="onchange"
      :show-file-list="true"
      :multiple="false"
      :auto-upload="false"
      accept=".csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-zip,application/x-zip-compressed, application/vnd.ms-excel,.xls,.doc,.docx,application/msword,"
      name="file"
      action="null"
    >
      <div @click="clearHandler">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </div>
    </el-upload>

    <el-dialog
      :close-on-click-modal="false"
      title="错误信息"
      :visible.sync="errorVisible"
      width="400px"
    >
      <div class="content" style="max-height: 300px; overflow: auto">
        <p v-for="item in failList" :key="item.index">
          <b>第{{ item.index }}行</b> {{ item.errorMsg }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="errorVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { BASE_URL } from '@/constant'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      file: null,
      loading: false,
      errorVisible: false,
      failList: [],
      params: {}
    }
  },
  methods: {
    onchange(file) {
      this.file = file.raw
    },

    clearHandler() {
      this.$refs.upload.clearFiles()
    },

    async submit() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.file)

        const res = await this.$api.classification.projectClazzImport(formData)

        this.$message({
          message: !res.errorList
            ? '导入成功'
            : `部分导入成功，其中有${res.errorList.length}条导入失败`,
          type: 'success',
          duration: res.fail < 0 ? 3000 : 5000
        })

        if (res.errorList) {
          this.errorVisible = true
          this.failList = res.errorList
        } else {
          this.show = false
        }

        this.$emit('getData')
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getDownloadTemplateMchUrl() {
      window.open(
        BASE_URL +
          '/downLoad/template/goodsClazzTemplate.xlsx?token=' +
          localStorage.getItem('token'),
        'target'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
  p {
    margin: 6px;
  }
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
