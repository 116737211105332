export default {
  data() {
    return {
      tableData: [],
      loading: false,
      params: {
        limit: 20,
        page: 1
      },
      tmp_params: {}
    }
  },
  created() {
    this.initParams()
    if (this.getData) this.getData = this.$utils.debounce(this.getData, 100)
  },
  methods: {
    initParams() {
      const _query = this.$route.query

      const query = {}
      Object.keys(_query).forEach(key => {
        if (key.indexOf('query_') !== -1) {
          query[key.replace('query_', '')] = _query[key]
        }
      })

      for (const i in query) {
        query[i] = isNaN(Number(query[i])) ? query[i] : Number(query[i])
      }

      Object.assign(this.params, query)
      this.params = { ...this.params, ...query }
      this.tmp_params = { ...this.params }
    },
    clearParams(fields) {
      const params = { ...this.params }
      if (fields) {
        Object.keys(params).forEach(key => {
          const item = fields.find(field => field.prop === key)

          if (item) {
            delete params[key]
          }
        })
      }

      this.params = fields ? params : {}
      this.tmp_params = { ...this.params }
    },
    updateParams(key, val, format = false) {
      const params = { ...this.params }

      params.page = 1
      params[key] = val
      Object.assign(this, { params })
    },
    replaceData() {
      this.params.page = 1
      for (const i in this.params) {
        if (this.params[i] === '') this.params[i] = undefined
        if (this.params[i] === undefined) delete this.params[i]
      }

      const params = { ...this.params }

      this.tmp_params = params
    }
  },
  watch: {
    tmp_params: {
      handler(val) {
        try {
          this.getData && this.getData()

          const name = this.name || this.$route.name
          const _query = this.$route.query
          const params = this.$route.params

          const query = {}

          // Object.keys(_query).forEach(key => {
          //   if (key.indexOf('query_') === -1) {
          //     // query[key] = _query[key]
          //     query[key] = _query[key]
          //   }
          // })
          // Object.keys(val).forEach(key => {
          //   query[`query_${key}`] = val[key]
          // })

          // this.$router.replace({ name, query, params })
        } catch (err) {
          console.log(err)
        } finally {
        }
      },
      deep: true
    }
  }
}
